$mobile-screen: 580px;
$tab-screen: 1000px;

// @media (min-width:#{$mobile-screen - 1}) and (max-width: #{$tab-screen}) {

@media (max-width: #{$tab-screen}) {
  .mobile {
    &-login {
      display: inline-flex;
    }
    &-text-center {
      text-align: center !important;
    }
  }

  .d {
    &-none-tab {
      display: none !important;
    }
    &-block-tab {
      display: block !important;
    }
    &-inline-tab {
      display: inline !important;
    }
    &-inlineblock-tab {
      display: inline-block !important;
    }
    &-flex-tab {
      display: flex !important;
    }
    &-inlineflex-tab {
      display: inline-flex !important;
    }
  }

  .col-tab {
    $end-width: 100%;
    $end-col: 12;
    @for $i from 1 through $end-col {
      &-#{$i} {
        $width: 100% / ($end-col / $i);
        -ms-flex: 0 0 $width;
        flex: 0 0 $width;
        max-width: $width;
      }
    }
  }

  .bg-tab-none {
    background: none !important;
  }

  .shadow-tab-none {
    box-shadow: none !important;
  }

  .p-tab-none {
    padding: 0px !important;
  }

  .px-tab-none {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .py-tab-none {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .pt-tab-none {
    padding-top: 0px !important;
  }

  .pl-tab-none {
    padding-left: 0px !important;
  }

  .pr-tab-none {
    padding-right: 0px !important;
  }

  .pb-tab-none {
    padding-bottom: 0px !important;
  }

  .m-tab-none {
    margin: 0px !important;
  }

  .mx-tab-none {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .my-tab-none {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mt-tab-none {
    margin-top: 0px !important;
  }

  .ml-tab-none {
    margin-left: 0px !important;
  }

  .mr-tab-none {
    margin-right: 0px !important;
  }
  
  .mb-tab-none {
    margin-bottom: 0px !important;
  }

  .ml-tab-auto {
    margin-left: auto !important;
  }

  .mr-tab-auto {
    margin-right: auto !important;
  }

  .width-tab {
    &-auto {
      width: auto;
    }
    &-full {
      width: 100%;
      &-vh {
        width: 100vh;
      }
    }
  }

  .bc-tab {
    &-none {
      border: none !important;
    }
  }

  .flex-direction-tab {
    &-column {
      flex-direction: column;
      &-reverse {
        flex-direction: column-reverse;
      }
    }
  }

  $direction-end: 16;
  $m-directions: "mb-tab", "mt-tab", "ml-tab", "mr-tab";
  @each $direction in $m-directions {
    @for $i from 1 to $direction-end {
      .#{$direction}-#{$i} {
        $spacing: 0.25rem * $i;
        @if $direction == "mb-tab" {
          margin-bottom: $spacing;
        } @else if $direction == "mt-tab" {
          margin-top: $spacing;
        } @else if $direction == "ml-tab" {
          margin-left: $spacing;
        } @else if $direction == "mr-tab" {
          margin-right: $spacing;
        }
      }
    }
  }

  $p-directions: "pb-tab", "pt-tab", "pl-tab", "pr-tab", "px-tab", "py-tab";
  @each $direction in $p-directions {
    @for $i from 1 to $direction-end {
      .#{$direction}-#{$i} {
        $spacing: 0.25rem * $i;
        @if $direction == "pb-tab" {
          padding-bottom: $spacing !important;
        } @else if $direction == "pt-tab" {
          padding-top: $spacing !important;
        } @else if $direction == "pl-tab" {
          padding-left: $spacing !important;
        } @else if $direction == "pr-tab" {
          padding-right: $spacing !important;
        } @else if $direction == "px-tab" {
          padding-right: $spacing !important;
          padding-left: $spacing !important;
        } @else if $direction == "py-tab" {
          padding-top: $spacing !important;
          padding-bottom: $spacing !important;
        }
      }
    }
  }

  .flex-tab {
    &-wrap {
      flex-wrap: wrap;
    }
    &-nowrap {
      flex-wrap: nowrap;
    }
    &-direction {
      &-row {
        flex-direction: row;
        &-reverse {
          flex-direction: row-reverse;
        }
      }
      &-column {
        flex-direction: column;
        &-reverse {
          flex-direction: column-reverse;
        }
      }
    }
    @for $i from 1 to 6 {
      &-#{$i} {
        flex: $i;
      }
    }
  }
}


@media (max-width: #{$mobile-screen}) {
  .bg-mobile-none {
    background: none !important;
  }

  .shadow-mobile-none {
    box-shadow: none !important;
  }

  .p-mobile-none {
    padding: 0px !important;
  }

  .px-mobile-none {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .py-mobile-none {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .pt-mobile-none {
    padding-top: 0px !important;
  }

  .pl-mobile-none {
    padding-left: 0px !important;
  }

  .pr-mobile-none {
    padding-right: 0px !important;
  }

  .pb-mobile-none {
    padding-bottom: 0px !important;
  }

  .m-tab-none {
    margin: 0px !important;
  }

  .mx-tab-none {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .my-tab-none {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mt-mobile-none {
    margin-top: 0px !important;
  }

  .ml-mobile-none {
    margin-left: 0px !important;
  }

  .mr-mobile-none {
    margin-right: 0px !important;
  }
  
  .mb-mobile-none {
    margin-bottom: 0px !important;
  }

  .ml-mobile-auto {
    margin-left: auto !important;
  }

  .mr-mobile-auto {
    margin-right: auto !important;
  }

  .d {
    &-none-mobile {
      display: none !important;
    }
    &-block-mobile {
      display: block !important;
    }
    &-inline-mobile {
      display: inline !important;
    }
    &-inlineblock-mobile {
      display: inline-block !important;
    }
    &-flex-mobile {
      display: flex !important;
    }
    &-inlineflex-mobile {
      display: inline-flex !important;
    }
  }

  .width-mobile {
    &-auto {
      width: auto;
    }
    &-full {
      width: 100%;
      &-vh {
        width: 100vh;
      }
    }
  }

  .col-mobile {
    $end-width: 100%;
    $end-col: 12;
    @for $i from 1 through $end-col {
      &-#{$i} {
        $width: 100% / ($end-col / $i);
        -ms-flex: 0 0 $width;
        flex: 0 0 $width;
        max-width: $width;
      }
    }
  }

  .bc-mobile {
    &-none {
      border: none !important;
    }
  }

  .flex-direction-mobile {
    &-column {
      flex-direction: column;
      &-reverse {
        flex-direction: column-reverse;
      }
    }
  }

  $direction-end: 16;
  $m-directions: "mb-mobile", "mt-mobile", "ml-mobile", "mr-mobile";
  @each $direction in $m-directions {
    @for $i from 1 to $direction-end {
      .#{$direction}-#{$i} {
        $spacing: 0.25rem * $i;
        @if $direction == "mb-mobile" {
          margin-bottom: $spacing;
        } @else if $direction == "mt-mobile" {
          margin-top: $spacing;
        } @else if $direction == "ml-mobile" {
          margin-left: $spacing;
        } @else if $direction == "mr-mobile" {
          margin-right: $spacing;
        }
      }
    }
  }

  $p-directions: "pb-mobile", "pt-mobile", "pl-mobile", "pr-mobile", "px-mobile", "py-mobile";
  @each $direction in $p-directions {
    @for $i from 1 to $direction-end {
      .#{$direction}-#{$i} {
        $spacing: 0.25rem * $i;
        @if $direction == "pb-mobile" {
          padding-bottom: $spacing !important;
        } @else if $direction == "pt-mobile" {
          padding-top: $spacing !important;
        } @else if $direction == "pl-mobile" {
          padding-left: $spacing !important;
        } @else if $direction == "pr-mobile" {
          padding-right: $spacing !important;
        } @else if $direction == "px-mobile" {
          padding-right: $spacing !important;
          padding-left: $spacing !important;
        } @else if $direction == "py-mobile" {
          padding-top: $spacing !important;
          padding-bottom: $spacing !important;
        }
      }
    }
  }

  .flex-mobile {
    &-wrap {
      flex-wrap: wrap;
    }
    &-nowrap {
      flex-wrap: nowrap;
    }
    &-direction {
      &-row {
        flex-direction: row;
        &-reverse {
          flex-direction: row-reverse;
        }
      }
      &-column {
        flex-direction: column;
        &-reverse {
          flex-direction: column-reverse;
        }
      }
    }
    @for $i from 1 to 6 {
      &-#{$i} {
        flex: $i;
      }
    }
  }

  .solid-right-full {
    border-right: none;
  }

  .login {
    &-form {
      &-list {
        flex-direction: column;
        & .login-form {
          &-user {
            width: 100%;
          }
          &-block {
            margin-top: 40px;
            margin-bottom: 40px;
            width: 100%;
            // position: relative;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // width: $block-width;
            &::after {
              display: block;
              background: var(--color-gray-1);
              width: 80%;
              height: 1px;
            }
          }
          &-thirdparty {
            width: 100%;
            // width: calc(50% - calc(#{$block-width} / 2));
          }
        }
      }
    }
  }
}




.guest {
  &-more {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    margin-right: 20px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    & &-wrapper {
      position: relative;
      z-index: 50;
    }
    & &-menu {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      background: var(--color-white);
      box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      margin-top: 12px;
      &:hover {
        display: block;
      }
      & ul {
        margin: 0;
        padding: 2px 4px;
        & li {
          color: var(--color-black-1);
          padding: 7px 10px;
          min-width: 100px;
          font-weight: bold;
          &:not(:first-child) {
            border-top: 1px solid var(--color-gray-2);
          }
          & a {
            color: var(--color-black-1);
            text-decoration: none;
          }
        }
      }
    }
    & &-btn {
      outline: none;
      border: none;
      background: none;
      cursor: pointer;
      height: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      & i {
        color: var(--color-black-2);
        font-size: 1.4rem;
      }
      &:focus {
        & + .guest-more-menu {
          display: block !important;
          z-index: 1000;
        }
      }
    }
  }
}

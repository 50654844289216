/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

$main-class: ".form";

#{$main-class} {
  &-group {
    $icon-width: 53px;
    display: block;
    width: 100%;
    position: relative;
    & &-label {
      font-size: 16px;
      color: var(--color-black-1);
      display: block;
    }
    & &-wrapper {
      position: relative;
    }
    & &-icon {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: $icon-width;
      display: flex;
      align-items: center;
      justify-content: center;
      & i {
        font-size: 1.7rem;
        color: var(--color-black-2);
      }
      & ~ input {
        padding-left: $icon-width;
      }
    }
    &-dark {
      & .form {
        &-group-icon {
          & i {
            color: var(--color-white);
          }
        }
        &-input {
          background-color: var(--color-black-2);
          color: var(--color-white);
        }
      }
    }
  }
  &-input {
    width: 100%;
    outline: none;
    font-size: 14px;
    padding: 18px 20px;
    border: none;
    border-radius: 15px;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
    $placeholder-text-color: var(--color-gray-1);
    color: var(--color-black-1);
    resize: none;
    &::placeholder {
      color: $placeholder-text-color;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: $placeholder-text-color;
    }
    &::-ms-input-placeholder {
      color: $placeholder-text-color;
    }
    &#{&}-shadow {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    &#{&}-rounded {
      border-radius: 500px;
    }
    &#{&}-strip {
      background: none;
      outline: none;
      width: 100%;
      border: none;
      border-bottom: 1px solid var(--b-color-black-2);
      padding-bottom: 8px;
    }
    &-label {
      color: var(--color-black-1);
      font-weight: normal;
      font-size: 14px;
    }
    &-message {
      font-weight: 500;
      font-size: 14px;
      color: var(--color-black-1);
      &#{&}-error {
        color: var(--color-red-1);
      }
    }
  }
  &-checkbox {
    $size: 28px;
    display: inline-block;
    position: relative;
    width: $size;
    height: $size;
    & &-mark {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      background-color: var(--color-white);
    }
    & ~ #{$main-class}-input-label {
      display: inline-block;
      margin-left: 10px;
      font-weight: 600;
    }
  }
}

select {
  &#{$main-class} {
    &-input {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      &::before {
        content: "R";
        position: absolute;
      }
    }
  }
}

textarea.form-input {
  padding: 12px 20px;
  min-height: 80px;
}

input[type="checkbox"] {
  &#{$main-class} {
    &-input {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
      opacity: 0;
      &:checked {
        & ~ #{$main-class}-checkbox-mark {
          background-color: var(--color-black-1);
          &::after {
            // left: 9px;
            // top: 5px;
            // width: 5px;
            // height: 10px;
            // border: solid var(--color-white);
            // border-width: 0 3px 3px 0;
            // -webkit-transform: rotate(45deg);
            // -ms-transform: rotate(45deg);
            // transform: rotate(45deg);
            // content: "";
          }
        }
      }
    }
  }
}

#{$main-class}-checkbox {
  &.light {
    & input[type="checkbox"]#{$main-class}-input {
      &:checked {
        & ~ #{$main-class}-checkbox-mark {
          background-color: var(--color-primary) !important;
        }
      }
    }
  }
}

/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

.main {
  &-toolbar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    z-index: $highest-index;
    background-color: var(--color-white);
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
  }
}

%flex-toolbar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.toolbar {
  &-wrapper {
    $padding-x: 20px;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding-left: $padding-x;
    padding-right: $padding-x;
  }
  &-menu {
    $scan-size: 70px;
    &-scan {
      width: $scan-size;
      height: $scan-size;
      position: absolute;
      bottom: 0;
      margin-bottom: 15px;
    }
    &-left {
      width: 50%;
      padding-right: $scan-size / 2;
      @extend %flex-toolbar;
    }
    &-right {
      width: 50%;
      padding-left: $scan-size / 2;
      @extend %flex-toolbar;
    }
  }
}

%btn-toolbar {
  outline: none;
  border: none;
  cursor: pointer;
}

.btn-toolbar {
  &-scan {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: var(--color-white);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    @extend %btn-toolbar;
    & a {
      text-decoration: none;
      background-color: none;
      outline: none;
      border: none;
    }
    & .ri-qr {
      &::before {
        color: var(--color-black-1);
        font-size: 2.4rem;
      }
    }
  }
  &-menu {
    outline: none;
    text-decoration: none;
    border: none;
    background: none;
    flex: 1;
    @extend %btn-toolbar;
    &-icon {
      color: var(--color-black-1);
      text-align: center;
      & i::before {
        color: var(--color-black-1);
        font-size: 1.6rem;
      }
    }
    &-label {
      color: var(--color-black-1);
      font-size: 10px;
      text-align: center;
      line-height: 10px;
      margin-top: 6px;
    }
    &.active {
      color: var(--color-cyan-3);
      & .btn-toolbar-menu {
        &-icon {
          color: var(--color-cyan-3);
          & i::before {
            color: var(--color-cyan-3);
          }
        }
        &-label {
          color: var(--color-cyan-3);
        }
      }
    }
  }
}

$te-class: ".texteditor";
$sidebar-wdith: 70px;
$sidebar-wrapper-padding-x: 8px;

#{$te-class} {
  &-sidebar {
    height: 100%;
    position: fixed;
    width: $sidebar-wdith;
    background-color: #444;
    top: 0;
    left: 0;
    z-index: $highest-index;
    & &-wrapper {
      width: 100%;
      height: 100%;
      padding: 12px $sidebar-wrapper-padding-x;
      display: flex;
      flex-direction: column;
    }
    & &-languages {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    & &-settings {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &-body {
    transition: all 0.3s ease-in-out;
    padding-left: $sidebar-wdith;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  &-window {
    // position: relative;
    height: 100%;
    & &-header {
      width: 100%;
      height: 40px;
      background-color: #444;
      color: var(--color-white);
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 3px 15px;
    }
    & &-main {
      width: 100%;
      height: 100%;
    }
    & &-title {
      text-align: left;
      flex-grow: 1;
      color: var(--color-white);
    }
    & &-tools {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      color: var(--color-white);
    }
    & &-tool {
      outline: none;
      border: none;
      background: none;
      color: var(--color-white);
      cursor: pointer;
      font-size: 1.1rem;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.2);
      }
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
  &-main {
    width: 50%;
    height: 100vh;
  }
  &-browser {
    width: 50%;
    height: calc(100vh - 40px);
  }
  &-output {
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
  }
  &-dropdown {
    &-languages {
      display: none;
      position: absolute;
      top: 0;
      left: ($sidebar-wdith - ($sidebar-wrapper-padding-x * 2)) - 5px;
      min-width: 10px;
      min-height: 10px;
      max-height: 170px;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: var(--color-white);
      border-radius: 12px;
      & .list-languages {
        margin: 0;
        padding: 0;
        & li.language {
          position: relative;
          &:first-child {
            & button {
              border-top-left-radius: 12px;
              border-top-right-radius: 12px;
            }
          }
          &:last-child {
            & button {
              border-bottom-left-radius: 12px;
              border-bottom-right-radius: 12px;
            }
          }
          &:not(:last-child) {
            border-bottom: 1px solid var(--color-gray-1);
          }
        }
      }
    }
  }
}

%button-sidebar {
  outline: none;
  border: none;
  cursor: pointer;
  background: var(--color-white);
  width: ($sidebar-wdith - ($sidebar-wrapper-padding-x * 2)) - 10px;
  height: ($sidebar-wdith - ($sidebar-wrapper-padding-x * 2)) - 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
  font-size: 1.4rem;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

button.btn {
  &-texteditor-sidebar {
    &-language {
      transition: all 0.3s ease-in-out;
      @extend %button-sidebar;
      & i.feather {
        transition: all 0.3s ease-in-out;
      }
      &.active {
        & i.feather {
          transform: rotate(45deg);
        }
        & .texteditor-dropdown-languages {
          display: block;
        }
      }
    }
    &-setting {
      @extend %button-sidebar;
    }
  }
  &-language {
    outline: none;
    border: none;
    background: var(--color-white);
    cursor: pointer;
    color: var(--color-black-1);
    padding: 9px 13px;
    text-align: left;
    width: 100%;
    &:hover {
      background: var(--color-gray-2);
    }
    &[disabled] {
      cursor: not-allowed;
      background: var(--color-black-1);
      color: var(--color-white);
      &:hover {
        background: var(--color-black-1);
      }
    }
  }
}

.bacod-texteditor {
  height: 100% !important;
  max-height: 100%;
  overflow-y: auto;
  & .CodeMirror {
    height: 100%;
  }
}

.list-projects {
  width: 400px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  & .btn-project {
    text-align: left;
    outline: none;
    border: 1px solid var(--color-gray-3);
    color: var(--color-white);
    background: #444;
    font-size: 1.2rem;
    padding: 11px 20px;
    text-decoration: none;
    margin-bottom: 18px;
    border-radius: 100px;
    // border-bottom-right-radius: 100px;
    width: 100%;
    &:hover {
      background: var(--color-red-1);
    }
  }
}


@media screen and (max-width: 630px) {
  #{$te-class} {
    &-main {
      width: 100%;
    }
    &-browser {
      position: fixed;
      right: -200px;
      bottom: -15%;
      z-index: 10;
      width: 600px;
      height: 50%;
      border-radius: 30px;
      transform: scale(0.2);
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      #{$te-class} {
        &-window-header {
          display: none;
        }
      }
      &.popup {
        width: 85%;
        height: 85%;
        transform: none;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        border-radius: 10px;
        z-index: $highest-index;
        box-shadow: 0 0 10px 50px rgba(0, 0, 0, 0.65);
        #{$te-class} {
          &-window-header {
            display: flex;
          }
        }
      }
    }
  }
}

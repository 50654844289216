.bacod {
  &-logo {
    height: 100px;
    width: 360px;
    background-image: url("../../assets/images/bacod.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-main-menu {
  }
}

$menu-class: ".primary-menu";

#{$menu-class} {
  &-list {
    margin: 0px !important;
    padding: 0px !important;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    & li#{$menu-class} {
      $margin-x: 30px;
      &:not(:first-child) {
        margin-left: $margin-x;
      }
      &:not(:last-child) {
        margin-right: $margin-x;
      }
    }
  }
  &-button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      & #{$menu-class} {
        &-circle {
          transform: scale(1.3) rotate(360deg);
        }
        &-title {
          transform: scale(1.3) translateY(15px);
        }
      }
    }
  }
  &-circle {
    $size: 75px;
    width: $size;
    height: $size;
    background-color: #333;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  &-title {
    width: 100%;
    text-align: center;
    color: var(--color-white);
    margin-top: 13px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}


.home {
  &-information {
    position: fixed;
    z-index: 200;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin-bottom: 15vh;
  }
  &-class {
    text-align: center;
  }
  &-attendance {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
}

.btn-join-class {
  &:not(:last-child) {
    margin-right: 30px;
  }
}


@media screen and (max-width: 570px) {
  .bacod-logo-wrapper {
    // padding-top: 16rem;
    // padding-top: 4rem;
  }
  .primary-menu-list {
    width: 300px;
    & li#{$menu-class} {
      margin: 0px 0px 30px 0px !important;
      width: 50% !important;
    }
  }
  .home-information {
    margin-bottom: 2rem;
  }
  #{$menu-class}-button {
    &:hover {
      & #{$menu-class} {
        &-circle {
          transform: none !important;
        }
        &-title {
          transform: none !important;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .bacod-logo {
    width: 260px;
  }
}

@media screen and (max-width: 350px) {
  .bacod-logo {
    width: 200px;
  }
  .primary-menu-list {
    width: 250px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
$menu-space: 20px;
$menu-size: 130px;

.menu {
  width: $menu-size;
  &-close {
    outline: none;
    border: none;
    background: none;
    color: var(--color-white);
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 18px;
    margin-left: 25px;
    font-size: 2rem;
    cursor: pointer;
    & i {
      display: inline-block;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      & i {
        transform: rotate(90deg);
      }
    }
  }
  &-wrapper {
    position: fixed;
    z-index: $highest-index + 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
  }
  &-background {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(8px);
    position: relative;
  }
  &-container {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }
  &-scroll {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 82%;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #808080;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #222;
      cursor: pointer;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: var(--color-cyan-1);
    }
  }
  &-main {
    margin: 0px !important;
    padding: 0px !important;
    width: 100%;
    display: flex;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    &-title {
      text-align: center;
      width: 100%;
      font-size: 2.2rem;
      color: var(--color-white);
      padding-top: 45px;
      padding-bottom: 45px;
      letter-spacing: 5px;
      font-weight: bold;
    }
  }
  &-effect {
    &-slide {
      transform: translateY(101vh);
    }
  }
  &-button {
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-circle {
    $circle-size: $menu-size - 45px;
    position: relative;
    border-radius: 50%;
    width: $circle-size;
    height: $circle-size;
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.6rem;
    color: var(--color-black);
  }
  &-title {
    color: var(--color-white);
    font-size: 1.2rem;
    margin-top: 12px;
    text-align: center;
  }
}

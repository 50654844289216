/*
==========================
THIS CODE BY RESITDC
ASK ME IF YOU HAVE ANY
QUESTION ABOUT THIS CODE
==========================
*/

$highest-index: 2000;

* {
  box-sizing: border-box;
  &::before,
  &::after {
    @extend *;
  }
}

:root {
  $primary-color: #52c0ff;

  --color-primary: #{$primary-color};
  --color-blue-1: #1aa5df;
  --color-blue-2: #23acce;
  --color-blue-3: #5dcee6;
  --color-cyan-1: #{$primary-color};
  --color-cyan-2: #00d3da;
  --color-cyan-3: #00bbc2;
  --color-cyan-4: #00a4a9;
  --color-cyan-5: #b6fdff;
  --color-red-1: #e22126;
  --color-red-2: #e41b13;
  --color-black-1: #222;
  --color-black-2: #737373;
  --color-black-3: #333;
  --color-black-4: #444;
  --color-black-5: #555;
  --color-black-6: #666;
  --color-black-7: #777;
  --color-black-8: #888;
  --color-black-9: #999;
  --color-gray-1: #c3c3c3;
  --color-gray-2: #eee;
  --color-gray-3: #f7f8f9;
  --color-gray-4: #dadada;
  --color-white: #fff;
  --color-background: #f9f9f9;
  --color-whatsapp: #25d366;
  --color-main-background: #000000;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

::-moz-selection {
  color: var(--color-white);
  background: var(--color-red-1);
}

::selection {
  color: var(--color-white);
  background: var(--color-red-1);
}

button,
textarea,
input,
select,
div,
span,
i,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: var(--color-main-background);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  margin: 0;
  font-size: 14px;
  color: var(--color-black-1);
  width: 100%;
  height: 100%;
}

#bacod-framework {
  position: relative;
  overflow-x: hidden;
  height: 100%;
  background-color: var(--color-mainbackground);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul, ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
$size: "mb", "mt", "ml", "mr", "mx", "my";
.img {
  &-rounded {
    &-xs {
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }
    &-sm {
      width: 60px;
      height: 60px;
      border-radius: 100%;
    }
    &-md {
      width: 90px;
      height: 90px;
      border-radius: 100%;
    }
    &-lg {
      width: 120px;
      height: 120px;
      border-radius: 100%;
    }
    &-xl {
      width: 150px;
      height: 150px;
      border-radius: 100%;
    }
  }
}

// $direction-end: 16;
// @each $direction in $m-directions {
//   @for $i from 1 to $direction-end {
//     .#{$direction}-#{$i} {
//       $spacing: 0.25rem * $i;
//       @if $direction == "mb" {
//         margin-bottom: $spacing;
//       } @else if $direction == "mt" {
//         margin-top: $spacing;
//       } @else if $direction == "ml" {
//         margin-left: $spacing;
//       } @else if $direction == "mr" {
//         margin-right: $spacing;
//       }
//     }
//   }
// }

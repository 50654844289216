@keyframes coinFlip {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}

@keyframes skeletonSlide {
  0% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(210px);
  }
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

.loading {
  &-coin {
    $size: 35px;
    width: $size;
    height: $size;
    background-color: var(--color-black-1);
    border-radius: 100%;
    animation: coinFlip 1s infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
  }
}

.loading-landscape {
  background: var(--dl-color-gray-1);
  width: 100%;
  height: 5vh;
  position: relative;
  overflow: hidden;
  display: inline-block;
  min-width: 20vh;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      transparent 0%,
      #fafafa 50%,
      transparent 100%
    );
    animation: load 1.3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

.skeleton-loading {
  background: rgba(93, 93, 93, 0.7);
  position: relative;
  overflow: hidden;
  display: inline-block;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(145, 145, 145, 0.7) 50%,
      transparent 100%
    );
    animation: load 1.3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}
.bacod-teams {
  width: 100%;
  position: relative;
  margin-top: 40px;
  & &-filters {
    justify-content: space-between;
  }
  & &-sort {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  & &-list {
    justify-content: space-between;
  }
}

.team {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 13px;
  padding-right: 13px;
  & &-avatar {
    $size: 108px;
    width: $size;
    height: $size;
    padding: 3px;
    background-color: var(--color-gray-3);
    border-radius: 100%;
    margin-bottom: 12px;
    &-image {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
  & &-name {
    width: 100%;
    color: var(--color-gray-2);
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.9rem;
    text-transform: capitalize;
    text-align: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & &-position {
    color: var(--color-gray-2);
    font-weight: normal;
    font-size: 1rem;
    text-align: center;
  }
}

.btn-bacod-teams-sort {
  outline: none;
  background: var(--color-black-3);
  border: none;
  color: var(--color-gray-3);
  font-size: 1.3rem;
  height: 43px;
  width: 43px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 50px;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 15px;
  }
}
/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-25px);} 
  60% {transform: translateY(-10px);} 
} 


.bounce { 
  animation: bounce 1.1s infinite;
}

.bold {
  font-weight: bold !important;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.cell-red {
  background: var(--b-color-red-1);
  color: var(--b-color-white);
}
.cell-yellow {
  background: var(--b-color-yellow-1);
  color: var(--b-color-white);
}
.align {
  &-wrap {
    flex-wrap: wrap;
  }
  &-nowrap {
    flex-wrap: nowrap;
  }
  &-x-space-around {
    align-content: space-around;
  }
  &-y-space-around {
    justify-content: space-around;
  }
  &-x-center {
    align-items: center;
  }
  &-y-center {
    justify-content: center;
  }
}
.text {
  &-nowrap {
    white-space: nowrap;
  }
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-center {
    text-align: center;
    &-important {
      text-align: center !important;
    }
  }
  &-left {
    text-align: left;
    &-important {
      text-align: left !important;
    }
  }
  &-right {
    text-align: right;
    &-important {
      text-align: right !important;
    }
  }
}
.display {
  &-flex {
    display: flex;
  }
  &-inline-flex {
    display: inline-flex;
  }
  &-none {
    display: none;
  }
  &-inline {
    display: inline;
  }
  &-inline-block {
    display: inline-block;
  }
  &-block {
    display: inline-block;
  }
}
.flex {
  &-wrap {
    flex-wrap: wrap;
  }
  &-nowrap {
    flex-wrap: nowrap;
  }
  &-direction {
    &-row {
      flex-direction: row;
      &-reverse {
        flex-direction: row-reverse;
      }
    }
    &-column {
      flex-direction: column;
      &-reverse {
        flex-direction: column-reverse;
      }
    }
  }
  @for $i from 1 to 6 {
    &-#{$i} {
      flex: $i;
    }
  }
}

.width {
  &-auto {
    width: auto;
  }
  &-full {
    width: 100%;
    &-vh {
      width: 100vh;
    }
  }
}

.height {
  &-full {
    height: 100%;
    &-vh {
      height: 100vh;
    }
  }
}

.pointer {
  &-none {
    pointer-events: none;
  }
}

.rounded {
  border-radius: 100%;
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-black-1);
    border-radius: 100px;
    color: var(--color-white);
    & span {
      &::before {
        color: var(--color-white);
        font-size: 1.2rem;
      }
    }
    &#{&}-sm {
      $size: 30px;
      width: $size;
      height: $size;
    }
    &#{&}-md {
      $size: 50px;
      width: $size;
      height: $size;
    }
    &#{&}-lg {
      $size: 80px;
      width: $size;
      height: $size;
      & span {
        &::before {
          color: var(--color-white);
          font-size: 2.3rem;
        }
      }
    }
  }
}

.icon-size {
  &-xs {
    font-size: 0.7rem;
  }
  &-sm {
    font-size: 1.2rem;
  }
  &-md {
    font-size: 1.6rem;
  }
  &-lg {
    font-size: 2rem;
  }
}

.fs {
  &-xs {
    font-size: 0.8rem;
  }
  &-sm {
    font-size: 1rem;
  }
  &-md {
    font-size: 1.2rem;
  }
  &-lg {
    font-size: 1.4rem;
  }
  &-xl {
    font-size: 1.6rem;
  }
}

.lh {
  &-xs {
    line-height: 0.4rem;
  }
  &-sm {
    line-height: 0.8rem;
  }
  &-md {
    line-height: 1.2rem;
  }
  &-lg {
    line-height: 1.6rem;
  }
  &-xl {
    line-height: 2rem;
  }
}

.hidden-anchor {
  text-decoration: none;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
}

.checkin-icon {
  $size: 20px;
  background-color: var(--color-cyan-2);
  width: $size;
  height: $size;
  border-radius: 100%;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  & i {
    color: var(--color-white);
    font-weight: 600;
  }
  &.top-right {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.scroll {
  &-horizontal {
    overflow-y: hidden;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background: #fff;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
    & div {
      white-space: nowrap;
    }
  }
}

.relation-rounded {
  background-color: var(--color-white);
  border: 1px solid var(--color-black-2);
  border-radius: 100px;
  padding: 3px 13px;
  font-size: 0.8rem;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
}

.label-strip {
  $strip-width: 60px;
  $strip-margin: 15px;

  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  font-size: 12px;
  &::before {
    width: $strip-width;
    content: "";
    height: 1px;
    background-color: var(--color-white);
    margin-right: $strip-margin;
  }
  &::after {
    width: $strip-width;
    content: "";
    height: 1px;
    background-color: var(--color-white);
    margin-left: $strip-margin;
  }
}

.resitdc-spin {
  display: inline-block !important;
  -webkit-animation: resitdc-spin 2s infinite linear;
  animation: resitdc-spin 2s infinite linear;
}

.resitdc-pulse {
  display: inline-block !important;
  -webkit-animation: resitdc-spin 1s infinite steps(8);
  animation: resitdc-spin 1s infinite steps(8);
}

.absolute {
  &-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-y-center {
    display: flex !important;
    align-items: center !important;
  }
  &-x-center {
    display: flex !important;
    justify-content: center !important;
  }
}

.page-title {
  position: relative;
  margin-bottom: 2rem;
  &::after {
    position: absolute;
    bottom: -15px;
    width: 150px;
    display: block;
    height: 2px;
    background-color: var(--color-white);
    content: "";
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

.underline {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  &::after {
    position: absolute;
    bottom: -15px;
    width: 200px;
    display: block;
    height: 2px;
    background-color: var(--color-white);
    content: "";
  }
  &-center {
    @extend .underline;
    &::after {
      display: flex;
      // justify-content: center;
      align-items: center;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

%border-style {
  border-style: solid;
}

.border {
  @for $i from 1 to 14 {
    &-#{$i} {
      border-width: #{$i}px;
      @extend %border-style;
    }
  }
}

.circle-check {
  background-color: var(--color-primary);
  font-size: 0.6rem;
  width: 15px;
  height: 15px;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: var(--color-black-1);
  position: absolute;
  top: 2px;
  margin-left: 8px;
}

.np {
  padding: 0px !important;
}

.nm {
  margin: 0px !important;
}

@-webkit-keyframes resitdc-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes resitdc-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

$direction-end: 16;
$m-directions: "mb", "mt", "ml", "mr", "mx", "my";
@each $direction in $m-directions {
  @for $i from 1 to $direction-end {
    .#{$direction}-#{$i} {
      $spacing: 0.25rem * $i;
      @if $direction == "mb" {
        margin-bottom: $spacing;
      } @else if $direction == "mt" {
        margin-top: $spacing;
      } @else if $direction == "ml" {
        margin-left: $spacing;
      } @else if $direction == "mr" {
        margin-right: $spacing;
      }
    }
  }
}
$p-directions: "pb", "pt", "pl", "pr", "px", "py";
@each $direction in $p-directions {
  @for $i from 1 to $direction-end {
    .#{$direction}-#{$i} {
      $spacing: 0.25rem * $i;
      @if $direction == "pb" {
        padding-bottom: $spacing !important;
      } @else if $direction == "pt" {
        padding-top: $spacing !important;
      } @else if $direction == "pl" {
        padding-left: $spacing !important;
      } @else if $direction == "pr" {
        padding-right: $spacing !important;
      } @else if $direction == "px" {
        padding-right: $spacing !important;
        padding-left: $spacing !important;
      } @else if $direction == "py" {
        padding-top: $spacing !important;
        padding-bottom: $spacing !important;
      }
    }
  }
}

.banned-logo {
  width: 200px;
}
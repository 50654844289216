.content-section {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
  & &-header {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    background-color: var(--color-gray-1);
    cursor: pointer;
    border-radius: 25px;
  }
  & &-title {
    padding: 10px 20px;
    color: var(--color-black-1);
    font-weight: bold;
    font-size: 1.4rem;
  }
  & &-body {
    padding: 15px 20px;
    border: 1px solid var(--color-black-2);
    display: none;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  &.active {
    & .content-section {
      &-header {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      &-body {
        display: block;
      }
    }
  }
}
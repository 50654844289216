/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

.main {
  &-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: $highest-index - 10;
    // background-color: #000;
    // filter: blur(7px);
  }
}

.navbar {
  &-wrapper {
    width: 100%;
    height: 100%;
    padding: 5px 30px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }
  &-title {
    color: var(--color-white);
    font-size: 16px;
    outline: none;
    border: none;
    background: none;
    text-decoration: none;
    width: auto;
    & a {
      @extend .navbar-title;
    }
  }
  &-icon {
    width: auto;
  }
  &-program {
    flex: 1;
    position: relative;
    text-align: right;
    padding-left: 20px;
    & &-title {
      font-size: 16px;
      color: var(--color-white);
      cursor: pointer;
    }
  }
}

$profile-navbar: ".profile-navbar";

#{$profile-navbar} {
  $avatar-size: 40px;
  $identity-padding-y: 5px;
  $profile-navbar-height: $avatar-size + ($identity-padding-y * 2);
  $margin-y: 20px;
  $dropdown-radius: 20px;
  display: inline-block;
  margin-top: $margin-y;
  margin-bottom: $margin-y;
  & &-wrapper {
    width: 100%;
    position: relative;
  }
  & &-identity {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: $identity-padding-y 6px $identity-padding-y 20px;
    border-radius: 50px;
    z-index: 13;
    transition: all 0.37s ease-in-out;
    color: var(--color-white);
    cursor: pointer;
    overflow: hidden;
    &::before {
      height: 100%;
      background-color: var(--color-white);
      width: 0%;
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      display: inline-block;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      color: var(--color-black-1);
      &::before {
        width: 100%;
      }
      & ~ #{$profile-navbar}-dropdown {
        display: block !important;
      }
    }
  }
  & &-name {
    color: inherit;
    font-weight: bold;
    font-size: 1.1rem;
    text-align: right;
    margin-right: 10px;
    line-height: 1.1rem;
    z-index: 1;
  }
  & &-avatar {
    width: $avatar-size;
    height: $avatar-size;
    z-index: 1;
    & img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
  & &-dropdown {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    width: 100%;
    background-color: var(--color-white);
    border-radius: 34px 34px $dropdown-radius $dropdown-radius;
    transition: all 0.3s ease-in-out;
  }
  & &-menu-list {
    margin: 0;
    padding: $profile-navbar-height 0px 0px 0px;
    width: 100%;
    & li {
      background-color: var(--color-gray-2);
      width: 100%;
      color: var(--color-black-1);
      &:last-child {
        border-bottom-left-radius: $dropdown-radius;
        border-bottom-right-radius: $dropdown-radius;
      }
      &:hover {
        background-color: var(--color-gray-1);
      }
      & #{$profile-navbar}-menu {
        outline: none;
        border: none;
        padding: 8px 15px;
        text-decoration: none;
        width: 100%;
        height: 100%;
        cursor: pointer;
        color: inherit;
        font-weight: bold;
        display: block;
        background: none;
      }
    }
  }
  &.hidden {
    & #{$profile-navbar}-dropdown {
      transform: scaleY(0.001);
      top: -52px !important;
    }
  }
}


@media screen and (max-width: 450px) {
  #{$profile-navbar}-name {
    display: none;    
    & &-identity {
      &:hover {
        &::before {
          width: 0px;
        }
        & ~ #{$profile-navbar}-dropdown {
          display: none !important;
        }
      }
    }
  }
}
.bs {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  & &-box {
    $top-radius: 23px;
    width: 100%;
    position: relative;
    background-color: var(--color-white);
    min-height: 20px;
    border-top-left-radius: $top-radius;
    border-top-right-radius: $top-radius;
  }
  & &-header {
    width: 100%;
    position: relative;
    min-height: 20px;
  }
  & &-title {
    color: var(--color-black-1);
    font-size: 1.3rem;
    font-weight: bold;
    padding: 22px 25px;
  }
  & &-toggle {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    &-button {
      $size: 27px;
      outline: none;
      border: none;
      text-decoration: none;
      background-color: var(--color-primary);
      color: var(--color-black-1);
      border-radius: 100%;
      width: $size;
      height: $size;
      & i {
        font-weight: bold;
        color: var(--color-black-1);
      }
    }
  }
}

.login {
  &-header {
    text-align: center;
    padding-top: 16vh;
  }
  &-logo {
    width: 140px;
  }
  &-title {
    font-size: 2.6rem;
    color: var(--color-white);
  }
  &-headline {
    font-size: 1.3rem;
    color: var(--color-gray-1);
    line-height: 1.9rem;
  }
  &-form {
    &-list {
      display: flex;
      flex-wrap: nowrap;
      & .login-form {
        $block-width: 120px;
        &-user {
          display: flex;
          align-items: center;
          width: calc(50% - calc(#{$block-width} / 2));
        }
        &-block {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: $block-width;
          &::after {
            display: block;
            background: var(--color-gray-1);
            width: 1px;
            height: 70%;
            content: "";
          }
        }
        &-thirdparty {
          width: calc(50% - calc(#{$block-width} / 2));
        }
      }
    }
  }
}

a.forgot-password {
  font-size: 1.1rem;
  color: var(--color-white);
  cursor: pointer;
  letter-spacing: 1ppx;
}

/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

.main {
  &-body {
    position: relative;
    width: 100%;
    min-height: 100vh;
    // background-color: var(--color-main-background);
    background-color: #121212;
    color: var(--color-white);
    &#{&}-with-navbar {
      padding-top: 70px;
    }
  }
}

.program {
  &-thumbnail {
    width: 100%;
    position: relative;
    height: 37vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &-live-date {
    position: absolute;
    background-color: var(--color-black-1);
    color: var(--color-white);
    text-align: center;
    padding: 12px 10px;
    width: 80%;
    border-radius: 10px;
    font-size: 14px;
    left: 0;
    bottom: -22px;
    margin-left: 10%;
  }
}

$sidebar-width: 300px;
$menu-sidebar-class: ".menu-sidebar";
$menu-sidebar-width: 280px;

@keyframes slideSidebarLeft {
  0% {
    transform: translateX(-#{$sidebar-width + 30px});
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideSidebarRight {
  0% {
    transform: translateX(#{$sidebar-width + 30px});
  }
  100% {
    transform: translateX(0);
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: $highest-index + 100;
  display: none;
  & &-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }
  & &-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  & &-box {
    background: var(--color-white);
    top: 0;
    height: 100%;
    width: $sidebar-width;
    position: absolute;
    z-index: 3;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
    transition: all ease-in-out;
  }
  &[position="left"] {
    & .sidebar-box {
      left: 0;
    }
  }
  &[position="right"] {
    & .sidebar-box {
      right: 0;
    }
  }
  &.show {
    display: block;
    &[position="left"] {
      & .sidebar-box {
        animation: slideSidebarLeft 0.3s;
      }
    }
    &[position="right"] {
      & .sidebar-box {
        animation: slideSidebarRight 0.3s;
      }
    }
  }
}

#{$menu-sidebar-class} {
  position: fixed;
  top: 0;
  z-index: $highest-index;
  bottom: 0;
  left: (-$menu-sidebar-width - 10px);
  height: 100%;
  width: $menu-sidebar-width;
  background-color: #444;
  transition: all 0.3s ease-in-out;
  &.active {
    left: 0;
  }
  & &-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 20px 12px;
  }
  & &-header {
    margin-bottom: 20px;
    padding-left: 14px;
    padding-right: 14px;
    position: relative;
  }
  & &-title {
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--color-white);
  }
  & &-body {
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &-close {
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
    color: var(--color-white);
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
  }
  & &-list {
    margin: 0 !important;
    padding: 0 !important;
    & #{$menu-sidebar-class}-item {
      width: 100%;
      position: relative;
      &-wrapper {
        $padding-y: 14px;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        color: var(--color-gray-2);
        cursor: pointer;
        outline: none;
        text-decoration: none;
        background-color: none;
        padding: $padding-y 16px;
        border-radius: 200px;
        &:hover {
          background-color: #333;
          color: var(--color-primary);
        }
      }
      &-icon {
        width: 30px;
        font-size: 1.6rem;
        color: inherit;
      }
      &-title {
        font-size: 1.1rem;
        padding-left: 10px;
        color: inherit;
      }
    }
  }
}
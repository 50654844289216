/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

.btn {
  $outline: ".btn-outline";
  $button-bgcolor: var(--color-gray-2) !default;
  $button-color: var(--color-black-1) !default;
  display: inline-block;
  font-weight: 400;
  background-color: $button-bgcolor;
  color: $button-color;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  border-radius: 8px;
  outline: none;
  transition: all ease-in-out 0.3s;
  &#{&}-black-1 {
    background-color: var(--color-black-1);
    color: var(--color-white) !important;
  }
  &#{&}-red-1 {
    background-color: var(--color-red-1);
    color: var(--color-white) !important;
  }
  &#{&}-primary {
    background-color: var(--color-primary);
    color: var(--color-black-1) !important;
    &#{$outline} {
      background: none !important;
      border: 2px solid var(--color-primary);
    }
  }
  &#{&}-bacod {
    background: linear-gradient(
      144deg,
      rgba(255, 0, 200, 1) 0%,
      rgba(182, 41, 216, 1) 34%,
      rgba(0, 143, 255, 1) 70%
    );
    color: var(--color-white) !important;
    &#{$outline} {
      // background: #000;
      // border: 3px solid transparent;
      position: relative;
      background-clip: padding-box;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: 3px;
        border-radius: inherit;
        background: var(--color-black-1);
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        &::before {
          background: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
  &#{&}-white {
    background-color: var(--color-white);
    color: var(--color-black-1) !important;
  }
  &#{&}-rounded {
    border-radius: 500px;
  }
  &#{&}-shadow {
    // box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  &#{&}-flying {
    $size: 62px;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 28px;
    margin-bottom: 28px;
    width: $size;
    height: $size;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: $highest-index;
    & i::before {
      font-size: 2.2rem;
    }
  }
  &#{&}-radius {
    &-xs {
      border-radius: 5px;
    }
    &-sm {
      border-radius: 10px;
    }
    &-md {
      border-radius: 15px;
    }
    &-lg {
      border-radius: 20px;
    }
    &-rounded {
      border-radius: 200px;
    }
  }
  &#{&}-fix {
    position: fixed;
    left: 28px;
    bottom: 0;
    width: calc(100% - (28px * 2));
    margin-bottom: 20px;
  }
  &#{&}-rectangle {
    $size: 54px;
    width: $size;
    height: $size;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    & i::before {
      font-size: 1.7rem;
    }
  }
  &#{&}-invisible {
    outline: none !important;
    border: none !important;
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  &#{&}[disabled] {
    cursor: not-allowed;
    &::after {
      position: absolute;
      z-index: 3;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: rgba(0, 0, 0, 0.3);
    }
    // color: var(--color-black-1) !important;
    // background-color: var(--color-gray-2) !important;
  }
  &-show-menu {
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
    color: var(--color-white);
    font-size: 1.4rem;
  }
}

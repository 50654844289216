.class {
  &-wrapper {
    $padding-y: 30px;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding-top: $padding-y;
    padding-bottom: $padding-y * 2;
  }
  &-list {
    margin: 0px !important;
    padding: 0px !important;
  }
  &-item {
    $item-icon-size: 80px;
    $item: #{&};
    position: relative;
    width: 100%;
    &:hover {

    }
    & &-detail {
      display: flex;
      align-items: center;
    }
    & &-icon {
      width: $item-icon-size;
      position: relative;
      display: flex;
      justify-content: center;
    }
    & &-circle {
      width: $item-icon-size;
      height: $item-icon-size;
      border-radius: 100%;
      color: var(--color-black-1);
      font-size: 2.2rem;
      background-color: var(--color-gray-3);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    & &-description {
      width: calc(100% - #{$item-icon-size});
      padding-left: 20px;
    }
    & &-title {
      color: var(--color-primary);
      font-size: 1.6rem;
      font-weight: 500;
      text-align: left;
      width: 100%;
      margin-bottom: 10px;
      cursor: pointer;
      text-decoration: none;
      display: block;
    }
    & &-mentor {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      &-item {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
      &-avatar {
        $avatar-size: 30px;
        width: auto;
        position: relative;
        &-image {
          width: $avatar-size;
          height: $avatar-size;
          border-radius: 100%;
        }
      }
      &-name {
        color: var(--color-white);
        font-size: 1rem;
        font-weight: 1.2rem;
        padding-left: 8px;
      }
    }
    &:not(:last-child) {
      $margin-bottom: 100px;
      margin-bottom: $margin-bottom;
      & .class-item-icon {
        &::after {
          $jarak: 15px;
          top: $item-icon-size + $jarak;
          position: absolute;
          height: $margin-bottom - ($jarak * 2);
          width: 5px;
          background-color: var(--color-white);
          content: "";
        }
      }
    }
  }
}
/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

.box {
  position: relative;
  background-color: var(--color-white);
  min-height: 3px;
  &#{&}-shadow {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  &#{&}-full-width {
    width: 100%;
  }
  &#{&}-radius {
    &-xs {
      border-radius: 5px;
    }
    &-sm {
      border-radius: 10px;
    }
    &-md {
      border-radius: 15px;
    }
    &-lg {
      border-radius: 20px;
    }
    &-xl {
      border-radius: 25px;
    }
  }
}

.chat {
  &-toolbar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: $highest-index;
    & &-wrapper {
      width: 100%;
      position: relative;
      background: none;
      padding: 15px 13px;
    }
  }
  &-container {
    width: 100%;
  }
  &-wrapper {
    $padding-x: 15px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 35px;
    padding-top: 20px;
    padding-left: $padding-x;
    padding-right: $padding-x;
    -webkit-touch-callout: text !important;
    -webkit-user-select: text !important;
    -khtml-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
  }
  &-input {
    flex: 1;
    padding-right: 12px;
  }
  &-submit {
    width: auto;
  }
  &-form-input {
    $min-height: 50px;
    $padding-y: 15px;
    width: 100%;
    min-height: $min-height;
    max-height: $min-height * 3;
    overflow-y: auto;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    background: var(--color-white);
    box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.1);
    outline: none;
    border-radius: 30px;
    position: relative;
    text-align: left;
    color: var(--color-black-1);
    font-size: 18px;
    padding: $padding-y 10px $padding-y 52px;
    cursor: text;
    &:empty {
      &::before {
        color: var(--color-gray-1);
        content: attr(placeholder);
      }
    }
  }
  &-row {
    display: flex;
    margin-bottom: 20px;
    -webkit-touch-callout: text !important;
    -webkit-user-select: text !important;
    -khtml-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
    &.left {
      justify-content: flex-start;
    }
    &.right {
      justify-content: flex-end;
    }
  }
  &-box {
    position: relative;
    background: var(--color-white);
    padding: 10px 16px;
    max-width: 350px;
    border-radius: 20px;
    box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.1);
    & &-text {
      -webkit-touch-callout: text !important;
      -webkit-user-select: text !important;
      -khtml-user-select: text !important;
      -moz-user-select: text !important;
      -ms-user-select: text !important;
      user-select: text !important;
      color: var(--color-black-1);
      font-size: 16px;
    }
    &.me {
      background: var(--color-cyan-5);
    }
    &[name] {
      &::before {
        display: block;
        width: 100%;
        font-weight: bold;
        font-size: 14px;
        color: var(--color-cyan-4);
        content: attr(name);
        margin-bottom: 5px;
        text-align: left;
      }
    }
    &[time] {
      &::after {
        display: block;
        width: 100%;
        font-size: 13px;
        line-height: 7px;
        color: var(--color-gray-1);
        content: attr(time);
        margin-top: 7px;
        margin-bottom: 2px;
        text-align: right;
      }
    }
  }
}

%btn-style {
  position: absolute;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-gray-1);
  z-index: 1;
}

.btn-chat {
  &-send {
    $size: 50px;
    width: $size;
    height: $size;
    outline: none;
    border: none;
    border-radius: 100%;
    background: var(--color-black-1);
    color: var(--color-white);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    & i {
      padding-top: 2px;
      padding-right: 2px;
      font-size: 1.7rem;
    }
  }
  &-attach {
    @extend %btn-style;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 13px;
    padding-left: 14px;
    padding-right: 14px;
    & i {
      font-size: 1.7rem;
      color: var(--color-gray-1);
    }
  }
  &-emoticon {
    @extend %btn-style;
  }
  &-camera {
    @extend %btn-style;
  }
}

@media screen and (max-width: 450px) {
  .chat {
    &-box {
      max-width: 83%;
    }
  }
}

.profile {
  $cover-height: 200px;
  $avatar-size: 130px;
  &-box-padding {
    padding: 15px 30px;
  }
  &-side-menu {
    position: sticky;
    top: 0px;
  }
  &-menu {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    & li {
      width: 100%;
      position: relative;
      background-color: none;
      font-size: 1.2rem;
      color: var(--color-gray-4);
      &.active {
        background-color: var(--color-black-3);
      }
      &:first-child {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:hover {
        background-color: var(--color-black-3);
      }
    }
  }
  &-cover {
    position: relative;
    width: 100%;
    height: 200px;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    margin-bottom: $avatar-size / 2;
    background-color: var(--color-black-6);
    &:hover {
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        content: "";
      }
      & .btn-edit-circle {
        display: flex !important;
      }
    }
  }
  &-avatar {
    padding: 3px;
    position: absolute;
    width: $avatar-size;
    height: $avatar-size;
    border-radius: 100%;
    background-color: var(--color-gray-3);
    top: 0;
    left: 0;
    margin-left: 30px;
    margin-top: $cover-height - ($avatar-size / 2);
    z-index: 3;
    & &-container {
      width: 100%;
      height: 100%;
      position: relative;
    }
    & &-tools {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.6);
      border-radius: 100%;
      display: none;
      align-items: center;
      justify-content: center;
    }
    & &-image {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
    &:hover {
      & .profile-avatar-tools {
        display: flex;
      }
    }
  }
  &-detail {
    padding: 15px 30px;
    .profile {
      &-name {
        font-size: 1.8rem;
        font-weight: bold;
        color: var(--color-white);
        position: relative;
      }
      &-title {
        font-size: 1rem;
        line-height: 1.6rem;
        color: var(--color-white);
      }
    }
  }
  &-setting-edit {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 25px;
    padding-left: 25px;
  }
}


.btn {
  &-edit-circle {
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    background: var(--color-white);
    color: var(--color-black-1);
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    z-index: 3;
  }
  &-profile-menu {
    cursor: pointer;
    background: none;
    width: 100%;
    height: 100%;
    position: relative;
    border: none;
    outline: none;
    font-size: inherit;
    text-align: left;
    padding: 14px 15px;
    color: inherit;
  }
  &-profile-avatar-tool {
    outline: none;
    border: none;
    background: var(--color-white);
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
  &-profile-setting-edit {
    outline: none;
    border: none;
    background: none;
    font-size: 1.6rem;
    color: var(--color-white);
    padding: 7px;
    cursor: pointer;
    &:hover {
      color: var(--color-primary);
    }
  }
}
/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

.color {
  &-white {
    color: var(--color-white) !important;
  }
  &-primary {
    color: var(--color-primary) !important;
  }
  &-blue-1 {
    color: var(--color-blue-1) !important;
  }
  &-blue-2 {
    color: var(--color-blue-2) !important;
  }
  &-blue-3 {
    color: var(--color-blue-3) !important;
  }
  &-red-1 {
    color: var(--color-red-1) !important;
  }
  &-black-1 {
    color: var(--color-black-1) !important;
  }
  &-black-2 {
    color: var(--color-black-2) !important;
  }
  &-gray-1 {
    color: var(--color-gray-1) !important;
  }
  &-gray-2 {
    color: var(--color-gray-2) !important;
  }
  &-whatsapp {
    color: var(--color-whatsapp) !important;
  }
}

.bg {
  &-white {
    background-color: var(--color-white) !important;
  }
  &-primary {
    background-color: var(--color-primary) !important;
  }
  &-blue-1 {
    background-color: var(--color-blue-1) !important;
  }
  &-blue-2 {
    background-color: var(--color-blue-2) !important;
  }
  &-blue-3 {
    background-color: var(--color-blue-3) !important;
  }
  &-red-1 {
    background-color: var(--color-red-1) !important;
  }
  &-black-1 {
    background-color: var(--color-black-1) !important;
  }
  &-black-2 {
    background-color: var(--color-black-2) !important;
  }
  &-black-3 {
    background-color: #333 !important;
  }
  &-black-4 {
    background-color: #444 !important;
  }
  &-black-5 {
    background-color: #555 !important;
  }
  &-black-6 {
    background-color: #666 !important;
  }
  &-black-7 {
    background-color: #777 !important;
  }
  &-black-8 {
    background-color: #888 !important;
  }
  &-black-9 {
    background-color: #999 !important;
  }
  &-gray-1 {
    background-color: var(--color-gray-1) !important;
  }
  &-gray-2 {
    background-color: var(--color-gray-2) !important;
  }
  &-whatsapp {
    background-color: var(--color-whatsapp) !important;
  }
  &-bacod {
    background: linear-gradient(
      144deg,
      rgba(255, 0, 200, 1) 0%,
      rgba(182, 41, 216, 1) 34%,
      #008fff 70%
    ) !important;
  }
}

.bc {
  &-white {
    border-color: var(--color-white) !important;
  }
  &-primary {
    border-color: var(--color-primary) !important;
  }
  &-blue-1 {
    border-color: var(--color-blue-1) !important;
  }
  &-blue-2 {
    border-color: var(--color-blue-2) !important;
  }
  &-blue-3 {
    border-color: var(--color-blue-3) !important;
  }
  &-red-1 {
    border-color: var(--color-red-1) !important;
  }
  &-black-1 {
    border-color: var(--color-black-1) !important;
  }
  &-black-2 {
    border-color: var(--color-black-2) !important;
  }
  &-black-3 {
    border-color: #333 !important;
  }
  &-black-4 {
    border-color: #444 !important;
  }
  &-black-5 {
    border-color: #555 !important;
  }
  &-black-6 {
    border-color: #666 !important;
  }
  &-black-7 {
    border-color: #777 !important;
  }
  &-black-8 {
    border-color: #888 !important;
  }
  &-black-9 {
    border-color: #999 !important;
  }
  &-gray-1 {
    border-color: var(--color-gray-1) !important;
  }
  &-gray-2 {
    border-color: var(--color-gray-2) !important;
  }
  &-whatsapp {
    border-color: var(--color-whatsapp) !important;
  }
  &-bacod {
    background: linear-gradient(
      144deg,
      rgba(255, 0, 200, 1) 0%,
      rgba(182, 41, 216, 1) 34%,
      #008fff 70%
    ) !important;
  }
}
